:root {
  --orange-color: rgb(253, 76, 12);
  --dark-red-color: #480e0e;
  --dark-color: #070917;
  --light-color: #e8f7fb;
  --light-red-color: #ec6868;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.wrapper {
  max-width: 1134px;
  margin: 0 auto;
}

/* buttons */

.btn {
  padding: 16px 32px;
  border-radius: 24px;
  color: white;
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
}

.btn.dark {
  background-color: var(--dark-red-color);
}

.btn.light {
  background-color: var(--orange-color);
}

/* Header */

header {
  background: rgb(253, 76, 12);
  height: 100vh;
  width: 100%;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 1134px;
  top: 0;
  padding: 36px 0;
  z-index: 100;
  transition: all 500ms ease;
}

nav.scrolled {
  background-color: var(--dark-color);
  box-shadow: 0 16px 14px -8px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 8px 8px;
  top: 0;
  padding: 12px;
}

nav .logo {
  font-family: "Raleway", sans-serif;
  font-weight: 900;
  font-size: 40px;
  color: white;
}

nav ul {
  display: flex;
  align-items: center;
  gap: 60px;
  list-style: none;
  margin: 0;
}

nav ul li a {
  font-weight: bold;
  font-size: 16px;
  color: white;
  text-decoration: none;
}

/* Hero section */

.hero-section {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-top: 60px;
  color: white;
}

.hero-section .left h1 {
  font-family: "Raleway", sans-serif;
  font-weight: 900;
  font-size: 50px;
  line-height: 125%;
  margin-bottom: 0;
}

.hero-section .left p {
  font-size: 16px;
  line-height: 200%;

  margin-bottom: 30px;
  width: 457px;
}

.hero-section .left {
  flex: 1;
}

.hero-section .right {
  align-self: flex-end;
}

.hero-section .right img {
  height: 520px;
  vertical-align: middle;
}

/* Ahievement Cards */

.achievement-card {
  position: absolute;
  min-width: 200px;
  color: var(--dark-red-color);
}

.achievement-card .content {
  border-radius: 24px;
  background-color: #fff;
  padding: 8px 32px;
  z-index: 10;
  position: relative;
}

.achievement-card.students-enrolled {
  left: 49%;
  top: 270px;
}

.achievement-card h3 {
  font-weight: bold;
  font-size: 36px;
  margin: 0;
  text-align: center;
  width: 100%;
  flex: 1;
}

.achievement-card img {
  flex: 1;
  height: 50px;
  width: 30px;
}

.achievement-card p {
  font-size: 16px;
  margin-top: -8px;
  margin-bottom: 8px;
  text-align: center;
}

.achievement-card .bg {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #ec6868 0%, #480e0e 100%);
  border-radius: 24px;
  top: 8px;
  left: 8px;
  z-index: 1;
}

.achievement-card.overall-rating {
  left: 47%;
  top: 470px;
}

.achievement-card .content div {
  display: flex;
  align-items: center;
  gap: 12px;
}

/* Testimonial section */

section {
  margin-top: 100px;
}

h2 {
  font-family: "Raleway", sans-serif;
  font-weight: 900;
  font-size: 55px;
  line-height: 120%;
  color: var(--dark-red-color);
  margin: 0;
}

h2.light {
  color: white;
}

section p {
  font-size: 16px;
  line-height: 200%;
  color: var(--dark-red-color);
  margin: 12px 0;
}

.testimonials-section {
  display: flex;
  gap: 100px;
  align-items: center;
}

.testimonials-section .mobile-btn {
  display: none;
}

.testimonials-section .testimonial-card {
  width: 442px;
  box-shadow: 0px 9px 36px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  margin-bottom: 50px;
  position: relative;
}

.testimonials-section .testimonial-card::before {
  content: "";
  width: 28px;
  position: absolute;
  height: 40px;
  top: -40px;
  left: 60px;
  background-color: var(--dark-red-color);
  border-radius: 24px 24px 0 0;
}

.testimonials-section .testimonial-card:last-child::before {
  left: 30px;
  top: -50px;
  border-radius: 0 0 24px 24px;
  height: calc(100% + 100px);
  z-index: -1;
}

.testimonials-section .testimonial-card .content {
  font-family: "Raleway", sans-serif;
  font-weight: 900;
  font-size: 18px;
  line-height: 150%;
  color: white;
  border-radius: 24px;
  padding: 24px 32px;
  background-color: var(--light-red-color);
}

.testimonials-section .testimonial-card:last-child .content {
  background-color: var(--orange-color);
}

.testimonials-section .testimonial-card h4 {
  font-family: "Raleway", sans-serif;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;
  margin: 0;
  color: var(--dark-red-color);
}

.testimonials-section .testimonial-card .info {
  padding: 24px 32px;
  background-color: white;
  border-radius: 24px;
}

.testimonials-section .testimonial-card .info p.company {
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  line-height: 120%;
  color: var(--dark-red-color);
}

.testimonials-section .testimonial-card img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  position: absolute;
  right: 32px;
  bottom: 45px;
  border-radius: 50%;
}

.testimonials-section .testimonial-card:last-child {
  transform: translateX(30px);
}

.testimonials-section .features {
  margin-bottom: 30px;
}

/* Courses section */

.courses-section {
  background: #480e0e;
  padding: 5px 0;
  width: 90%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.courses-section h2 {
  text-align: center;
}

.courses-section .course-cards {
  display: flex;
  gap: 70px;
}

.courses-section .course-cards img {
  width: 100%;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
  border-radius: 24px;
}

.courses-section .course-card {
  background: rgb(235, 234, 234);
  border-radius: 24px;
  padding: 0px 32px;
  margin: 40px 0;
  position: relative;
}

.courses-section .course-card::after {
  content: "";
  height: 8px;
  left: 32px;
  right: 32px;
  bottom: 0;
  background: var(--dark-red-color);
  border-radius: 24px;
  position: absolute;
}

.courses-section .course-card h3 {
  font-family: "Raleway", sans-serif;
  font-weight: 900;
  font-size: 30px;
  line-height: 150%;
  color: var(--dark-red-color);
}

.courses-section .course-card .info {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
}

.courses-section .course-card .duration {
  font-family: "Raleway", sans-serif;
  font-weight: 900;
  font-size: 16px;
  line-height: 150%;
  white-space: nowrap;
  color: var(--dark-red-color);
}

.courses-section .wrapper {
  position: relative;
}

.courses-section .wrapper .btn {
  position: absolute;
  right: 0;
}

/* App Section */

.app-section {
  text-align: center;
}

.app-section .app-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 25px;
}

.app-section .app-btn {
  padding: 16px 30px;
  background: black;
  color: white;
  display: flex;
  align-items: center;
  gap: 15px;
  min-width: 310px;
}

.app-section .app-btn span {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: white;
}

/* Footer */

footer {
  background: var(--dark-color);
  color: white;
  margin-top: 100px;
  padding: 70px 0;
}

footer a {
  text-decoration: none;
  color: #fff;
}

footer h3 {
  color: var(--orange-color);
}

footer ul {
  list-style: none;
  padding-left: 20px;
}

footer ul li {
  margin: 16px 0;
}

footer .links-container {
  display: grid;
  grid-template-columns: 2fr 3fr;
}

footer .social {
  margin-left: 20px;
}

footer .social a {
  margin-right: 28px;
}

footer form {
  margin-left: 20px;
  margin-top: 16px;
  position: relative;
}

footer form input {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 200%;
  color: black;
  border-radius: 8px;
  padding: 8px 160px 8px 32px;
  border: none;
  width: 100%;
}

footer form .submit-btn {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 8px;
  background: var(--dark-red-color);
  border: none;
  padding: 8px 32px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: white;
  cursor: pointer;
}

footer .copyright {
  margin-top: 36px;
}

nav.mobile-nav,
.mobile-menu-container {
  display: none;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  box-shadow: 2px 2px 3px #232222;
  z-index: 100;
}

@media (max-width: 1100px) {
  .btn {
    padding: 12px 32px;
  }

  .wrapper {
    padding: 0 100px;
  }

  nav {
    width: 100%;
    left: 0;
    right: 0;
    padding: 36px 100px;
  }

  nav.scrolled {
    padding: 16px 100px;
  }

  header,
  .hero-section,
  header .wrapper {
    max-height: 1100px;
  }

  .hero-section {
    flex-direction: column;
    position: relative;
  }

  .hero-section .left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .hero-section .left h1 {
    font-size: 50px;
    line-height: 125%;
  }

  .hero-section .left .btn {
    align-self: start;
  }

  .hero-section .right {
    position: absolute;
    bottom: 0;
  }

  .hero-section .right img {
    transform: translateX(80px);
  }

  .hero-section .achievement-card {
    position: relative;
    margin-bottom: 80px;
  }

  .achievement-card.students-enrolled,
  .achievement-card.overall-rating {
    left: unset;
    top: unset;
  }

  .hero-section .achievement-cards {
    align-self: flex-start;
    margin: 40px;
  }

  h2 {
    font-size: 40px;
  }

  .testimonials-section {
    flex-direction: column;
    align-items: stretch;
    gap: 50px;
  }

  .testimonials-section .testimonial-card {
    width: 100%;
    margin-bottom: 40px;
  }

  .testimonials-section .testimonial-card:last-child::before {
    top: -40px;
    height: calc(100% + 80px);
  }

  .testimonials-section .testimonial-card:last-child {
    margin-bottom: 0;
  }

  .testimonials-section .desktop-btn {
    display: none;
  }
  .testimonials-section .mobile-btn {
    display: block;
    align-self: end;
    order: 3;
  }

  .testimonials-section .right {
    order: 1;
  }
  .testimonials-section .left {
    order: 2;
  }

  .testimonials-section .right p {
    display: inline;
  }

  .testimonials-section h2 {
    margin-bottom: 20px;
  }

  .courses-section .course-cards {
    flex-direction: column;
    gap: 80px;
  }

  .courses-section .course-card {
    margin: 0;
  }

  .courses-section h2 {
    margin-bottom: 40px;
  }

  .courses-section .course-card img {
    height: 300px;
    object-fit: cover;
  }

  .courses-section .course-card h3 {
    font-size: 30px;
  }

  .courses-section .wrapper .btn {
    right: 100px;
  }

  .courses-section .course-card:last-child {
    margin-bottom: 50px;
  }

  footer .links-container {
    grid-template-columns: 1fr 1fr;
  }

  footer .links-container .links:last-child {
    grid-column: 1/3;
  }
}

@media (max-width: 800px) {
  nav {
    display: none;
  }

  nav.mobile-nav {
    display: flex;
  }
  nav.mobile-nav.scrolled {
    background-color: var(--orange-color);
    box-shadow: 0 9px 21px -5px rgba(0, 0, 0, 0.3);
    border-radius: 0 0 8px 8px;
    padding: 8px 100;
  }

  .mobile-menu-container {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 100%;
    background: var(--dark-color);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
    transition: all 400ms ease;
  }

  .mobile-menu-container.active {
    left: 0;
  }

  .mobile-menu-container .close-icon {
    position: fixed;
    top: 32px;
    right: 100px;
    opacity: 0;
    pointer-events: none;
    transition: all 400ms ease;
  }

  .mobile-menu-container.active .close-icon {
    opacity: 1;
    pointer-events: auto;
  }

  .mobile-menu-container ul {
    list-style: none;
    padding: 0;
  }
  .mobile-menu-container ul li {
    margin: 30px 0;
    text-align: center;
  }

  .mobile-menu-container ul li a {
    color: white;
    text-decoration: none;
    font-size: 18px;
  }
}

@media (max-width: 700px) {
  .wrapper {
    padding: 0 32px;
  }

  .hero-section .left p {
    width: 100%;
  }

  .app-section .app-buttons {
    flex-direction: column;
  }

  nav.mobile-nav {
    padding: 32px;
  }

  nav.mobile-nav.scrolled {
    padding: 8px 32px;
  }

  .mobile-menu-container .close-icon {
    right: 32px;
  }

  .hero-section .left h1 {
    font-size: 36px;
  }
  .hero-section .right img {
    height: 400px;
    transform: translateX(50px);
  }
  .hero-section .right {
    overflow: hidden;
    transform: translateX(30px);
    width: 75%;
  }

  .achievement-card h3 {
    font-size: 30px;
  }

  .hero-section .achievement-card {
    margin-bottom: 48px;
  }

  .hero-section .achievement-cards {
    margin: 0;
  }

  h2 {
    font-size: 30px;
  }

  .testimonials-section .testimonial-card .info p {
    font-size: 18px;
  }

  .testimonials-section .testimonial-card .info p.company {
    font-size: 14px;
  }

  .testimonials-section .testimonial-card img {
    height: 80px;
    width: 80px;
  }

  .courses-section .wrapper {
    padding: 0;
  }

  .courses-section .course-card img {
    height: 240px;
  }

  .courses-section .wrapper .btn {
    right: 32px;
  }

  footer .links-container {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 40px;
  }

  footer form input {
    padding: 8px 32px;
    font-size: 10px;
  }

  footer .links-container .links:last-child {
    grid-column: 1/2;
  }

  footer ul {
    padding: 0;
  }

  footer form .submit-btn {
    top: 130%;
    left: 50%;
    transform: translateX(-50%);
  }

  footer .copyright {
    margin-top: 80px;
    text-align: center;
  }
}

@media (max-width: 376px) {
  .hero-section .right img {
    height: 300px;
    transform: translateX(80px);
  }
  .hero-section .achievement-cards {
    display: flex;
    gap: 20px;
    margin: 0;
  }

  .achievement-card {
    max-width: 120px;
    min-width: unset;
    margin-top: 20px;
  }

  .achievement-card .content {
    padding: 8px;
  }

  .achievement-card h3 {
    font-size: 18px;
    line-height: 200%;
  }

  .achievement-card p {
    font-size: 12px;
  }

  .achievement-card .bg {
    display: none;
  }

  .achievement-card img {
    flex: unset;
    height: unset;
    width: 30px;
  }
}

@media (max-width: 360px) {
  .testimonials-section .testimonial-card img {
    right: 20px;
    bottom: 65px;
  }
}

@media (max-width: 820px) {
  .testimonials-section .testimonial-card img {
    right: 30px;
    bottom: 20px;
  }
}

@media (max-width: 303px) {
  .wrapper {
    overflow-x: hidden;
  }
  .hero-section .left h1 {
    font-size: 24px;
  }
  .hero-section .left p {
    font-size: 13px;
  }

  footer form .submit-btn {
    padding: 8px 5px;
  }

  footer form input {
    padding: 8px 10px;
    font-size: 8px;
  }
}

@media (max-width: 280px) {
  .testimonials-section .testimonial-card img {
    right: 20px;
    bottom: 100px;
    height: 60px;
    width: 60px;
  }
  .testimonials-section .testimonial-card:first-child img {
    bottom: 130px;
  }
}
