.hea {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  background-color: rgb(253, 76, 12);
  h1 {
    font-weight: bold;
    font-size: 18px;
    color: white;
  }
}

.privacy {
  width: 100vw;
  height: 100vh;
  position: relative;

  .btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
  }

  .modal-content {
    position: absolute;    
    left: 50%;
    transform: translateX(-50%);
    line-height: 1.4;    
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
    z-index: 101;
  }
  p {
    line-height: 200%;
    font-size: 12px;
  }

  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    border: none;
    cursor: pointer;
  }

  .contact-details {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
