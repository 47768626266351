.modal {
  z-index: 100;
  body.active-modal {
    overflow-y: hidden;
  }

  .btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
  }

  .modal,
  .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
  }
  .overlay {
    background: rgba(49, 49, 49, 0.8);
    z-index: 100;
  }

  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
    z-index: 101;
  }
  .modal-content p {
    line-height: 200%;
  }

  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    border: none;
    cursor: pointer;
  }

  .contact-details {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 531px) {
  .modal {
    .modal-content {     
      padding: 14px 9px;

      .contact-details{
        display: none;
      }
    }
  }
}

@media (max-width: 300px) {
  .modal {
    .modal-content {     
      padding: 14px 9px;
      min-width: 260px;      
      p{
        font-size: 10px;
      }
    }
  }
}